import Keycloak from 'keycloak-js';

// Keycloak configuration
const keycloakConfig = {
    url: process.env.REACT_APP_AUTH_DOMAIN, 
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    clientId:process.env.REACT_APP_AUTH_CLIENTID,
};

// Initialize Keycloak
const keycloak = new Keycloak(keycloakConfig);

export default keycloak;