import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { BusinessTypes } from "../actions/business-actions";

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    fetchingOne: null,
    fetchingAll: null,
    fetchingBusinessSummary: false,
    fetchingQrCode: false,
    fetchingIndustries: false,
    creating: null,
    updating: null,
    deleting: null,
    closingNotification: false,
    closedNotication: null,
    business: null,
    businesses: null,
    qrCode: null,
    industries: null,
    businessSummary: null,
    errorOne: null,
    errorAll: null,
    errorCreating: null,
    errorUpdating: null,
    errorDeleting: null,
    errorClosingNotification: null,
    errorBusinessSummary: null,
    errorQrCode: null,
    errorIndustries: null
})

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state) => { return { ...state, fetchingOne: true, business: null } }
// request the data from an api
export const allRequest = (state) => { return { ...state, fetchingAll: true, business: null } }
// request data from an api
export const businessSummaryRequest = (state) => {
    return { ...state, fetchingBusinessSummary: true, businessSummary: null }
}
// request to update from an api
export const createRequest = (state) => { return { ...state, creating: true } }
// request to update from an api
export const updateRequest = (state) => { return { ...state, updating: true } }

// request to delete from an api
export const deleteRequest = (state) => { return { ...state, deleting: true } }

// request to closeNotification from an api
export const closeNotificationRequest = (state) => { return { ...state, closingNotification: true } }

// request the data from an api
export const qrCodeRequest = (state) => { return { ...state, fetchingQrCode: true, qrCode: null } }

// request the data from an api
export const industriesRequest = (state) => {
    return { ...state, fetchingIndustries: true, industries: null }
}

// successful api lookup for single entity
export const success = (state, action) => {
    const { business } = action
    return { ...state, fetchingOne: false, errorOne: null, business }
}
// successful api lookup for all entities
export const allSuccess = (state, action) => {
    const { businesses } = action
    return { ...state, fetchingAll: false, errorAll: null, businesses }
}
// successful api lookup for a business summary
export const businessSummarySuccess = (state, action) => {
    const { businessSummary } = action
    return { ...state, fetchingBusinessSummary: false, errorBusinessSummary: null, businessSummary }
}
// successful api create
export const createSuccess = (state, action) => {
    const { business } = action
    return { ...state, creating: false, errorCreating: null, business }
}
// successful api update
export const updateSuccess = (state, action) => {
    const { business } = action
    return { ...state, updating: false, errorUpdating: null, business }
}
// successful api delete
export const deleteSuccess = (state) => {
    return { ...state, deleting: false, errorDeleting: null, business: null }
}

// successfully closeNotification 
export const closeNotificationSuccess = (state, action) => { 
    const {business} = action
    return { ...state, closingNotification:false , business } }

// successful api lookup for qrCode
export const qrCodeSuccess = (state, action) => {
    const { qrCode } = action
    return { ...state, fetchingQrCode: false, errorQrCode: null, qrCode }
}

// successful api lookup for industries
export const industriesSuccess = (state, action) => {
    const { industries } = action
    return { ...state, fetchingIndustries: false, errorIndustries: null, industries }
}

// Something went wrong fetching a single entity.
export const failure = (state, action) => {
    const { error } = action
    return { ...state, fetchingOne: false, errorOne: error, business: null }
}
// Something went wrong fetching all entities.
export const allFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingAll: false, errorAll: error, businesses: null }
}
// Something went wrong fetching business summary
export const businessSummaryFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingBusinessSummary: false, errorBusinessSummary: error, businessSummary: null }
}
// Something went wrong creating.
export const createFailure = (state, action) => {
    const { error } = action
    return { ...state, creating: false, errorCreating: error }
}
// Something went wrong updating.
export const updateFailure = (state, action) => {
    const { error } = action
    return { ...state, updating: false, errorUpdating: error }
}
// Something went wrong deleting.
export const deleteFailure = (state, action) => {
    const { error } = action
    return { ...state, deleting: false, errorDeleting: error }
}
// Something went wrong deleting.
export const closedNoticationFailure = (state, action) => {
    const { error } = action
    return { ...state, closingNotification: false, errorClosingNotification: error }
}

//something went wrong fetching qrCode
export const qrCodeFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingQrCode: false, errorQrCode: error, qrCode: null }
}

//something went wrong fetching industries?
export const industriesFailure = (state, action) => {
    const { error } = action
    return { ...state, fetchingIndustries: false, errorIndustries: error, industries: null }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [BusinessTypes.BUSINESS_REQUEST]: request,
    [BusinessTypes.BUSINESS_ALL_REQUEST]: allRequest,
    [BusinessTypes.BUSINESS_SUMMARY_REQUEST]: businessSummaryRequest,
    [BusinessTypes.BUSINESS_CREATE_REQUEST]: createRequest,
    [BusinessTypes.BUSINESS_UPDATE_REQUEST]: updateRequest,
    [BusinessTypes.BUSINESS_DELETE_REQUEST]: deleteRequest,
    [BusinessTypes.BUSINESS_CLOSE_NOTIFICATION_REQUEST]: closeNotificationRequest,
    [BusinessTypes.BUSINESS_QR_CODE_REQUEST]: qrCodeRequest,
    [BusinessTypes.BUSINESS_INDUSTRIES_REQUEST]: industriesRequest,

    [BusinessTypes.BUSINESS_SUCCESS]: success,
    [BusinessTypes.BUSINESS_ALL_SUCCESS]: allSuccess,
    [BusinessTypes.BUSINESS_SUMMARY_SUCCESS]: businessSummarySuccess,
    [BusinessTypes.BUSINESS_CREATE_SUCCESS]: createSuccess,
    [BusinessTypes.BUSINESS_UPDATE_SUCCESS]: updateSuccess,
    [BusinessTypes.BUSINESS_DELETE_SUCCESS]: deleteSuccess,
    [BusinessTypes.BUSINESS_CLOSE_NOTIFICATION_SUCCESS]: closeNotificationSuccess,
    [BusinessTypes.BUSINESS_QR_CODE_SUCCESS]: qrCodeSuccess,
    [BusinessTypes.BUSINESS_INDUSTRIES_SUCCESS]: industriesSuccess,

    [BusinessTypes.BUSINESS_FAILURE]: failure,
    [BusinessTypes.BUSINESS_ALL_FAILURE]: allFailure,
    [BusinessTypes.BUSINESS_SUMMARY_FAILURE]: businessSummaryFailure,
    [BusinessTypes.BUSINESS_CREATE_FAILURE]: createFailure,
    [BusinessTypes.BUSINESS_UPDATE_FAILURE]: updateFailure,
    [BusinessTypes.BUSINESS_DELETE_FAILURE]: deleteFailure,
    [BusinessTypes.BUSINESS_CLOSE_NOTIFICATION_FAILURE]: closedNoticationFailure,
    [BusinessTypes.BUSINESS_QR_CODE_FAILURE]: qrCodeFailure,
    [BusinessTypes.BUSINESS_INDUSTRIES_FAILURE]: industriesFailure
})
