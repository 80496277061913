import React, { useEffect, useCallback, useRef } from 'react'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from "react-redux";
import Loading from '../../../Components/Loading';
import { Toast } from "primereact/toast";
import BusinessCustomersActions from "../../../Redux/transition/customers/business-customer.reducer";
import { useTranslation } from "react-i18next";

const Confirmation = ({ validFilteredRows, nonDuplicateNumbers, state, setState, navigateToImportPage, handleBack }) => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const toast = useRef(null);

    const uploadingCustomers = useSelector((state) => state.businessCustomers.uploading);
    const uploadedCustomers = useSelector((state) => state.businessCustomers.uploadCustomer);
    const applying = useSelector((state) => state.businessCustomers.applying);
    const applySuccess = useSelector((state) => state.businessCustomers.appliedImport);
    const applyError = useSelector((state) => state.businessCustomers.appliedImportError);

    const applyImport = useCallback((id) => dispatch(BusinessCustomersActions.businessCustomerApplyRequest(id)), [dispatch]);
    const validImports = nonDuplicateNumbers.length

    useEffect(() => {
        if (uploadingCustomers) {
            setState((state) => ({ ...state, uploading: true }));
        } else {
            setState((state) => ({ ...state, uploading: false }));
        }
        if (uploadedCustomers) {
            setState((state) => ({ ...state, uploadedCustomers: uploadedCustomers }));
        }
        if (!applying && applySuccess !== null && state.applying ) {
            showSuccess();
        }
        if (!applying && applyError && state.applying) {
            showError(t("business_customers.apply_error"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadingCustomers,applying, uploadedCustomers ,applyError, applySuccess, state.applying]);

    const showSuccess = () => {
        toast.current.show({ severity: "success", summary: t("business_customers.success_summary"), detail: t("business_customers.apply_success"), life: 3000 });
    };

    const showError = (message, user) => {
        toast.current.show({ severity: "error", user, summary: t("business_customers.error_summary"), detail: message, life: 3000 });
    };

    const id = uploadedCustomers?.id;

    const handleApply = () => {
        if (state.uploadedCustomers?.appliedOn === null ) {
            applyImport(id)
            setState((state) => ({ ...state, applying: true}));
        }
    }

    return (
        <div>
            <Toast
                ref={toast}
                onRemove={(message) => {
                    if (message.severity === "success") {
                        setState((state) => ({ ...state, applying: false }));
                        navigateToImportPage();
                    }
                }}
            />
            {state.uploading ? <Loading /> :
                <Card className='main-upload-card'>
                    <div className='pl-4 pr-4 pt-5'>
                        <p className='title-text mb-0'>{t("business_customers.import-completed-header")}</p>
                        <p className='sub_text mb-0'>{t("business_customers.completed_text")}</p>

                        <div className='grid mt-4'>
                            <div className='col-5 success-import tip-div completed-tip-div'>
                                <p className='success-import-title'>{t("business_customers.success_completed_import_tip1")}</p>
                                <br />
                                <p className='success-import-text mb-0'>{t("business_customers.success_completed_import_tip2", { value: validImports })}</p>
                                <br />
                                <p className='success-import-text2'>{t("business_customers.success_completed_import_tip3")}</p>
                                <br />
                                <Button className='success-import-button' onClick={()=> handleApply()} loading={applying}>{t("business_customers.apply_button")}</Button>
                            </div>

                        </div>

                    </div>
                    <div className='cont-btn'>
                        <Button className='import_done' onClick={navigateToImportPage}>
                            {t("done")}
                        </Button>
                        <Button className='import_button' onClick={handleBack}>
                            {t("business_customers.new_file")}
                        </Button>
                    </div>
                </Card>
            }
        </div>
    )
}

export default Confirmation