import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';

const KeycloakProviderWrapper = ({ children }) => {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "check-sso", // Check silently if user is already logged in
        checkLoginIframe: true, // Use iframe to check login status
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`, // URL for silent token validation
        pkceMethod: "S256", // PKCE flow for more secure authentication
        promiseType: 'native', // Native promises
      }}
      
    >
      {children}
    </ReactKeycloakProvider>
  );
};

export default KeycloakProviderWrapper;
