import React from 'react'
import { FullWidthCardTemplate, SmallCardTemplate, MediumCardTemplate } from '../Products/ProductSummaryInsights';

const DetailCard = (props) => {
    const { state, t, totalActiveSubscriptions, defMembership, totalReceivedPayments, setDisplayBasic, setState, customerId, setVisibleInvite } = props;

    const sideMenu = [
        {
            label: defMembership?.role !== "VIEWER" ? t('business_customers.edit_customer') : null,
            icon: defMembership?.role !== "VIEWER" ? "pi-pencil" : null,
            onclick: () => {
                setDisplayBasic(true)
                setState((state) => {
                    return {
                        ...state,
                        data: state,
                        id: customerId,
                        firstName: state.customerRecord?.firstName,
                        lastName: state.customerRecord?.lastName,
                        customerNum: state.customerRecord?.customerNum,
                        email: state.customerRecord?.email,
                    }
                })
            }
        }, {
            label: t('business_customers.add'),
            icon: "pi-plus-circle",
            onclick: () => {
                setVisibleInvite(true)
            }
        },
    ]

    const currencyAmt = (val) => {
        return (
            <div className='product-card-text'>
                <span className='count-curren'>{defMembership.business.country.currency}{" "}</span>
                {Number(val).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
        );
    };

    const subscriberMainCardDetails = [
        {
            page: "subscriber-detail",
            name: state.customerRecord?.name,
            phoneNo: state.customerRecord?.phoneNo,
            email: state.customerRecord?.email,

            refTitle: t("business_customers.customer_ref"),
            reference: state.customerRecord?.reference,
            cusNumTitle: t("business_customers.customer_id"),
            cusNum: state.customerRecord?.customerNum,
        }
    ]

    const subscriberSummary = [
        {
            title: t("business_customers.total_payments"),
            subDesc: t("business_customers.payments"),
            value: totalReceivedPayments,
            cardInfo: t("business_customers.tooltip_total_payments", { subscriber: state.customerRecord?.name }),
            className: "small-card-title"
        },
        {
            title: t("business_customers.total_collected_payments"),
            cardInfo: t("business_customers.tooltip_collected_payments", { subscriber: state.customerRecord?.name }),
            subDesc: null,
            value: currencyAmt(state.businessCustomerSubscriptionAmounts?.collectedAmount),
            className: 'small-card-title'
        },
        {
            title: t("business_customers.total_revenue"),
            cardInfo: t("business_customers.tooltip_revenue_payments", { subscriber: state.customerRecord?.name }),
            subDesc: null,
            value: currencyAmt(state.businessCustomerSubscriptionAmounts?.redeemable),
            className: 'small-card-title'
        },
        {
            title: t("business_customers.total_missed_payments"),
            cardInfo: t("business_customers.tooltip_missed_payments", { subscriber: state.customerRecord?.name }),
            subDesc: null,
            value: currencyAmt(state.businessCustomerSubscriptionAmounts?.missedAmount),
            className: 'small-card-title'
        },
        {
            title: t("business_customers.active_subs"),
            cardInfo: t("business_customers.tooltip_active_subscriptions", { subscriber: state.customerRecord?.name }),
            subDesc: t("subscriptions"),
            value: totalActiveSubscriptions,
            className: 'small-card-title'
        }
    ]
 
    const subscriberInsightCard = [
        {
            type: "subscriber-details-1",
            title: t("subscriber.upcoming"),
            cardInfo: t("business_customers.tooltip_upcoming_payments", { subscriber: state.customerRecord?.name }),
            dataFromSubscriberDetail: state.recentSubscriptionActivityObject,
            flexAmount: t("business_customers.flexAmount"),
            currency: defMembership.business.country.currency,
            dateClassName: "yellow-progress-txts"
        },
        {
            type: "subscriber-details-2",
            title: t("subscriber.recent_payments"),
            cardInfo: t("business_customers.tooltip_recent_collected_payments", { subscriber: state.customerRecord?.name }),
            dataFromSubscriberDetail: state.recentPaymentsObject,
            flexAmount: t("business_customers.flexAmount"),
            currency: defMembership.business.country.currency,
            dateClassName: "green-success-txts"
        },
        {
            type: "subscriber-details-3",
            title: t("subscriber.recent_missed_payments"),
            cardInfo: t("business_customers.tooltip_recent_missed_payments", { subscriber: state.customerRecord?.name }),
            flexAmount: t("business_customers.flexAmount"),
            currency: defMembership.business.country.currency,
            dataFromSubscriberDetail: state.recentMissedPaymentsObject,
            dateClassName: "red-cancelled-txts"
        }
    ]

    return (
        <div>
            <div className='grid nested-grid'>
                <section className='col-9'>
                    <div className='grid mb-3'>
                        <FullWidthCardTemplate summary={subscriberMainCardDetails} />
                    </div>

                    <h6 className="title-tile ml-0 mt-5">{t("products.summary")}</h6>

                    <div className='grid mb-3'>
                        <SmallCardTemplate summary={subscriberSummary} />
                    </div>

                    <div className='grid'>
                        <MediumCardTemplate summary={subscriberInsightCard} />
                    </div>
                </section>

                <section className='col-3'>
                    <div id="side-menu">
                        {sideMenu.map(({ label, icon, onclick }, i) => (
                            <div className="col-12" key={i}>
                                <span onClick={onclick}>
                                    <i className={"pi " + icon} />
                                    <span> {label}</span>
                                </span>
                                {defMembership?.role !== "VIEWER" ? <hr /> : null}
                            </div>))
                        }
                    </div>
                </section>

            </div>

        </div>
    )
}
export default DetailCard;