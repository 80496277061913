import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Upload from './uploadSteps/Upload.js'
import { useNavigate } from "react-router-dom";
import ImportPreview from "./uploadSteps/ImportPreview.js";
import Confirmation from "./uploadSteps/Confirmation.js";
import DefMembershipActions from '../../Redux/actions/defMembership-actions.js';
import BusinessCustomersActions from "../../Redux/transition/customers/business-customer.reducer.js";
import { useSelector, useDispatch } from "react-redux";

export const ImportFileUpload = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const defMembership = useSelector((state) => state.defMembership.defMembership);

  const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch]);
  const importBusinessCustomers = useCallback((businessId, membershipId, file) => dispatch(BusinessCustomersActions.businessCustomerUploadRequest(businessId, membershipId, file)), [dispatch]);

  const [activeStep, setActiveStep] = useState(0);
  const [filteredRowsNoZero, setFilteredRowsNoZero] = useState([]);
  const [validFilteredRows, setValidFilteredRows] = useState([]);
  const [nonDuplicateNumbers, setNonDuplicateNumbers] = useState([]);
  const [duplicateNumbers, setDuplicateNumbers] = useState([]);

  const [state, setState] = useState({
    enterpriseCustomers: null,
    importedCustomers: [],
    uploadedCustomers: null,
    entries: null,
    file: {},
    count: null,
    page: 0,
    sort: "importedOn,desc",
    size: 20,
    loading: true,
    uploading: false,
    applying: false,
  })

  useEffect(() => {
    if (!defMembership) {
      getMembership();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpload = () => {
    let file = state.file;

    const reader = new FileReader();

    reader.onload = (event) => {
      const csvContent = event.target.result;
      // Split CSV content into rows
      const rows = csvContent.split("\n").filter(row => row.trim() !== "");
      // Assuming the first row is the header
      const headers = rows[0].split(",");
      // Process data rows, starting from row 1 (after the header)
      const dataRows = rows.slice(1).map(row => {
        const values = row.split(",");
        // Create an object with header names as keys
        let rowObject = {};
        headers.forEach((header, index) => {
          // Assign values to the respective header
          rowObject[header.trim()] = values[index]?.trim();
        });
        return rowObject;
      });

      // Filter rows where phone number start with zero
      const startingWithZero = dataRows.filter(row => {
        const phoneNumber = row['Phone number'];
        return phoneNumber && phoneNumber.startsWith('0');
      });
      setValidFilteredRows(startingWithZero)

      // Filter to skip rows where phone numbers are duplicates
      const phoneCountMap = startingWithZero.reduce((acc, row) => {
        const phoneNumber = row['Phone number'];

        if (phoneNumber) {
          acc[phoneNumber] = (acc[phoneNumber] || 0) + 1;
        }
        return acc;
      }, {});

      const nonDuplicatesOnly = startingWithZero.filter(row => {
        const phoneNumber = row['Phone number'];
        return phoneCountMap[phoneNumber] === 1;
      });
      setNonDuplicateNumbers(nonDuplicatesOnly);

      const duplicatesOnly = startingWithZero.filter(row => {
        const phoneNumber = row['Phone number'];
        return phoneCountMap[phoneNumber] !== 1;
      });
      setDuplicateNumbers(duplicatesOnly)

      // Filter rows where phone number doesn't start with zero
      const notStartingWithZero = dataRows.filter(row => {
        const phoneNumber = row['Phone number'];
        return phoneNumber && !phoneNumber.startsWith('0');
      });
      setFilteredRowsNoZero(notStartingWithZero);
    };

    reader.readAsText(file);

  };

  const handleContinue = () => {
    if (activeStep === 2) {
      setActiveStep(0)
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setState((state) => ({...state, file: {}}))
    if (activeStep === 2) {
      setActiveStep(0)
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const navigateToImportPage = () => navigate("/subscriptions/subscribers", { state: {activeIndex: 1}});

  const steps = [
    { label: 'business_customers.upload', icon: "pi-upload" },
    { label: 'business_customers.import_preview', icon: "pi-search" },
    { label: 'business_customers.complete', icon: "pi-check-circle" }
  ];

  return (
    <div>
      <div className="card card-div">
        <div className='fav-card-content'>
          <ul className="list-none p-0 m-0 flex flex-column md:flex-row ">
            {steps.map(({ label, icon }, i) => (
              <li key={i} className={`relative ${i + 1 !== steps.length ? "mr-0 md:mr-8" : ""}  flex-auto`}>
                <div className={`step-box step-box-${activeStep === i ? "active" : i < activeStep ? "pre" : "post"}`}>
                  <i className={`pi step-box-icon ${activeStep === i ? "step-box-active-icon " + icon : i < activeStep ? "step-box-pre-icon pi-check-circle" : "step-box-post-icon " + icon}`}></i>
                  <div>
                    <div className={`step-box-title step-box-${activeStep === i ? "active" : i < activeStep ? "pre" : "post"}-title`}>{t("totalTransfers.step")} {i + 1}</div>
                    <span className={`step-box-des step-box-${activeStep === i ? "active" : i < activeStep ? "pre" : "post"}-des  hidden md:block`}>{t(label)}</span>
                  </div>
                </div>
                {i + 1 !== steps.length && <div className="hidden md:block step-box-bar" ></div>}
              </li>))}
          </ul>
        </div>

      </div>
      <div>
        {activeStep === 0 && <Upload {...{ handleContinue, handleUpload, navigateToImportPage,state,setState }} />}
        {activeStep === 1 && <ImportPreview {...{ handleBack, handleContinue, navigateToImportPage, validFilteredRows, filteredRowsNoZero,nonDuplicateNumbers, importBusinessCustomers, defMembership, state, setState, duplicateNumbers }} />}
        {activeStep === 2 && <Confirmation {...{ validFilteredRows, nonDuplicateNumbers,duplicateNumbers, navigateToImportPage, state ,setState, handleBack}} />}
      </div>
    </div>
  )
}
