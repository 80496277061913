import React from 'react'
import { useSelector } from 'react-redux';
// import { Card } from 'primereact/card';
import { useTranslation, Trans } from "react-i18next";
import moment from "moment";
import { FullWidthCardTemplate, SmallCardTemplate } from '../../../Products/ProductSummaryInsights';
import "../../../../Styles/pages/subscriptionDetail.scss"

const DetailCard = (props) => {
    const { subscribers, totalMissedPayments } = props
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const { t } = useTranslation("common");
    const newDate = moment(props.subscribers?.nextBillingDate).format("LLL")
    const pausedDate = moment(props.subscribers?.pausedOn).format("LLL")
    const cancelledDate = moment(props.subscribers?.cancelledOn).format("LLL")
    const completedDate = moment(props.subscribers?.completedOn).format("LLL")
    const currency = props.defMembership?.business.country.currency
    const amount = (props.subscriber?.plan?.flex !== false && props.subscribers?.cost === null) ? (t("subscriber.flex_true"))
        : (currency + " " + Number.parseFloat(props.subscribers?.cost).toFixed(2))

    const subscriptionCreatedOnDate = moment(subscribers.createdOn).format("LL")
    const subscriptionLastModifiedOnDate = moment(subscribers.lastModifiedDate).format("LL")

    const moneyTemplate = (amount = 0) => {
        const isCollected = amount === Number(subscribers.collected);
        const isRevenue = amount === Number(subscribers.redeemable);
        const commonStyle = { color: isRevenue ? "#008DD5" : isCollected ? "#66BB6A" : "#000000" };
        return (
            <div className='flex'>
                <p className="count-curren" style={commonStyle}>{defMembership.business.currency}</p>
                <p className="amt-fig" style={commonStyle}>
                    {amount ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount?.toFixed(2)) : "0.00"}
                </p>
            </div>)
    }

    const totalMissed = () => <div className='missed-pay'>{totalMissedPayments ? totalMissedPayments : "0"}</div>;

    const mainCardPlan = [
        {
            page: "subscription-detail",
            subscribers,
            t,
            defMembership,
            subscriptionCreatedOnDate,
            subscriptionLastModifiedOnDate,
        }
    ]

    const smallCardData = [
        {
            title: t("business_customers.total_payments"),
            subDesc: t("business_customers.payments"),
            value: subscribers.successCount,
            cardInfo: t("subscriber.tooltip", { status: t("Completed"), subscription: subscribers?.plan?.name }),
            className: "small-card-title"
        },
        {
            title: t("subscriber.total_collected"),
            value: moneyTemplate(subscribers.collected),
            cardInfo: t("subscriber.tooltip", { status: t("Collected"), subscription: subscribers?.plan?.name }),
            className: "small-card-title"
        },
        {
            title: t("subscriber.total_revenue"),
            value: moneyTemplate(subscribers.redeemable),
            cardInfo: t("subscriber.tooltip", { status: t("Revenue"), subscription: subscribers?.plan?.name }),
            className: "small-card-title"
        },
        {
            title: t("subscriber.total_missed"),
            subDesc: t("business_customers.payments"),
            value: totalMissed(),
            cardInfo: t("subscriber.tooltip", { status: t("Missed"), subscription: subscribers?.plan?.name }),
            className: "small-card-title"
        }
    ]

    return (
        <div className="grid detail_card" style={{ marginBottom: "20px" }}>
            <div className='grid col-9'>
                <FullWidthCardTemplate summary={mainCardPlan} />

                <div className='col-12 mt-0'>
                    <div className='notice-alert'>
                    <div className='flex align-items-center'>
                        <i className='pi pi-info-circle mr-1' />
                        <p className='notice-label'>{t("subscriber.notice")}</p>
                    </div>
                    <br />
                    <p className='payment-added-alert2'>
                        {subscribers.status === "CANCELLED" ? <Trans i18nKey={t("subscriber.modified-date")}
                            values={{ status: t("Cancelled"), date: cancelledDate }}
                            components={{ 1: <strong style={{ color: "#d0021b", fontFamily: "RobotoCondensed" }} /> }} />
                            : subscribers.status === "PAUSED" ? <Trans i18nKey={t("subscriber.modified-date")}
                                values={{ status: t("Paused"), date: pausedDate }}
                                components={{ 1: <strong style={{ color: "#FFB300", fontFamily: "RobotoCondensed" }} /> }} />
                                :
                                subscribers.status === "COMPLETE" ? <Trans i18nKey={t("subscriber.modified-date")}
                                    values={{ status: t("Completed"), date: completedDate }}
                                    components={{ 1: <strong style={{ color: "#666678", fontFamily: "RobotoCondensed" }} /> }} />
                                    :
                                    <Trans i18nKey={t("subscriber.due")}
                                        values={{ value1: amount, value2: newDate }}
                                        components={{ 1: <strong style={{ color: "#256029", fontFamily: "RobotoCondensed" }} /> }} />
                        }
                    </p>
                    </div>
                </div>

                <h6 className="title-tile ml-1 mb-1 mt-1">{t("products.summary")}</h6>
                <div className='grid col-12'>
                    <SmallCardTemplate summary={smallCardData} />
                </div>
            </div>
            <div className='col-3'>
                {defMembership.role !== "VIEWER" && <div id="side-menu">
                    {subscribers?.status === "PAUSED" ?
                        <div className="col-12">
                            <span onClick={() => {
                                props.setConfirmResume(true)
                                props.setState((state) => { return { ...state, resumed: true } })
                            }}>
                                <i className="pi pi-refresh" >
                                </i>{t('subscriber.resume_label')}
                            </span>
                            <hr />
                        </div> : ""}

                    {
                        subscribers?.status === "ACTIVE" &&
                        <div className="col-12">
                            <span onClick={() => {
                                props.setConfirmReschedule(true)
                            }}
                            >
                                <i className="pi pi-calendar" >
                                </i>{t('subscriber.reschedule_label')}
                            </span>
                            <hr />
                        </div>
                    }

                    {subscribers?.status === "ACTIVE" ?
                        <div className="col-12">
                            <span onClick={() => {
                                props.setConfirmPause(true)
                            }}
                            >
                                <i className="pi pi-pause" >
                                </i>{t('subscriber.pause_label')}
                            </span>
                            <hr />
                        </div> : ""}

                    {subscribers?.status !== "CANCELLED" && subscribers?.status !== "EXPIRED" && subscribers?.status !== "COMPLETE" &&
                        <div className="col-12" >
                            < span onClick={() => {
                                props.setConfirmCancel(true)
                                props.setState((state) => { return { ...state, cancelled: true } })
                            }}>
                                <i className="pi pi-stop-circle" ></i>
                                {t('subscriber.cancel_label')}
                            </span>
                        </div>}
                    <hr />
                </div>}
            </div>

        </div >
    )
}
export default DetailCard