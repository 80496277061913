import React from "react";
import PropTypes from "prop-types";
import "../Styles/components/AppTopbar.scss";
import "../Styles/pages/profile.scss";
import logo from "../assets/images/Reveknew_Logo_All_White.png"

const AppTopbar = ({
	onMenuButtonClick,
	onTopbarMenuButtonClick,
	onTopbarItemClick,
	topbarMenuActive,
	activeTopbarItem,
}) => {

	return (
		<div className="layout-topbar clearfix" style={{ backgroundColor: "#D0021B", padding:'0.7rem' }}>

			<button className="layout-topbar-logo p-link">
				<img id="layout-topbar-logo" alt="babylon-layout" width='270px' src={logo} />
			</button>

		</div>
	);
};

AppTopbar.defaultProps = {
	onMenuButtonClick: null,
	onTopbarMenuButtonClick: null,
	onTopbarItemClick: null,
	topbarMenuActive: false,
	activeTopbarItem: null,
	profileMode: null,
	horizontal: null,
};

AppTopbar.prototype = {
	onMenuButtonClick: PropTypes.func.isRequired,
	onTopbarMenuButtonClick: PropTypes.func.isRequired,
	onTopbarItemClick: PropTypes.func.isRequired,
	topbarMenuActive: PropTypes.bool.isRequired,
	activeTopbarItem: PropTypes.string,
	profileMode: PropTypes.string,
	horizontal: PropTypes.bool,
};

export default AppTopbar;
