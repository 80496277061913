import { createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    businessRequest: ['businessId'],
    businessAllRequest: ['options'],
    businessSummaryRequest: ['businessId'],
    businessCreateRequest: ['business'],
    businessUpdateRequest: ["businessId", 'business'],
    businessDeleteRequest: ['businessId'],
    businessCloseNotificationRequest: ['businessId'],
    businessQrCodeRequest: ['businessId'],
    businessIndustriesRequest: ["industry"],

    businessSuccess: ['business'],
    businessAllSuccess: ['businesses'],
    businessSummarySuccess: ['businessSummary'],
    businessCreateSuccess: ['business'],
    businessUpdateSuccess: ['business'],
    businessCloseNotificationSuccess: [],
    businessDeleteSuccess: [],
    businessQrCodeSuccess: ["qrCode"],
    businessIndustriesSuccess: ["industries"],

    businessFailure: ['error'],
    businessAllFailure: ['error'],
    businessSummaryFailure: ['error'],
    businessCreateFailure: ['error'],
    businessUpdateFailure: ['error'],
    businessDeleteFailure: ['error'],
    businessCloseNotificationFailure: ['error'],
    businessQrCodeFailure: ['error'],
    businessIndustriesFailure: ['error']
})

export const BusinessTypes = Types
export default Creators
