import React, { useEffect, useState, useCallback, } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../Styles/pages/transactions.scss";
import "../../Styles/pages/payments.scss";
import "../../Styles/pages/activities.scss";
import "../../Styles/pages/report.scss"
import "../../Styles/pages/dashboard.scss";
import "../../Styles/pages/subscriptionDetail.scss"
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import PlanGroupActions from "../../Redux/transition/plan-group/plan-group.reducer";
import PaymentTrackActions from "../../Redux/transition/payment-track/payment-track.reducer";
import moment from 'moment';
import { ActionBar } from './actionBar';
import CustomDataTable from "../../Components/CustomDataTable";
import MarkAsPaid from "../../Components/MarkAsPaid";
import { Button } from "primereact/button";
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Payments = () => {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedType, setSelectedType] = useState({ name: "Collected payments", code: 'CP', alias: "collected" });
    const [period, setPeriod] = useState({ name: t("dashboard.3_months_duration"), code: 'MONTHLY' });
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedBcId, setSelectedBcId] = useState(null);
    const [filteredCustomer, setFilteredCustomer] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [dates, setDates] = useState(null);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [page, setPage] = useState(0);
    const [data, setData] = useState([]);
    const [cleared, setCleared] = useState(false);
    const [typeChanged, setTypeChanged] = useState(false);

    const [state, setState] = useState({
        enterpriseCustomers: null,
        businessCustomers: [],
        businessCustomerReports: {},
        invitesData: [],
        paymentsData: [],
        subscriptionsData: [],
        invitationInfo: {},
        paymentsInfo: {},
        subscriptionInfo: {},
        options: null,
        date: null,
        planReports: [],
        planCharts: [],
        planInvitesData: [],
        planSubscriptionsData: [],
        planPaymentsData: [],
        input: '',
        submitInput: false,
        selectedCustomer: null,
        filteredCustomers: [],
        id: null,
        isAmountTrue: null,
        first: 0,
        size: 15,
        paginate: false,
        flex: false,
        allProductsObject: [],
        page: 0
    });

    //redux state from store
    const defMembership = useSelector((state) => state.defMembership.defMembership)
    const fetchingCollected = useSelector((state) => state.paymentTracks.fetchingCollectedPayments);
    const fetchingMissed = useSelector((state) => state.paymentTracks.fetchingMissedPayments);
    const fetchingExternal = useSelector((state) => state.paymentTracks.fetchingExternalPayments);
    const collectedPayments = useSelector((state) => state.paymentTracks.collectedPaymentTracks);
    const missPayments = useSelector((state) => state.paymentTracks.missedPaymentTracks);
    const externalPayments = useSelector((state) => state.paymentTracks.externalPaymentTracks);
    const planGroupsGraphedActive = useSelector((state) => state.planGroups.planGroupsGraphedActive)
    const allPlanGroups = useSelector((state) => state.planGroups.planGroups)

    //redux Dispatch actions
    const dispatch = useDispatch();
    const getMembership = () => dispatch(DefMembershipActions.defMembershipRequest())
    const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch])
    const getCollectedPayments = useCallback((businessId, filter, options) => dispatch(PaymentTrackActions.collectedPaymentTrackRequest(businessId, filter, options)), [dispatch]);
    const getMissedPayments = useCallback((businessId, filter, options) => dispatch(PaymentTrackActions.missedPaymentTrackRequest(businessId, filter, options)), [dispatch]);
    const getExternalPayments = useCallback((businessId, filter, options) => dispatch(PaymentTrackActions.externalPaymentTrackRequest(businessId, filter, options)), [dispatch]);
    const getAllPlanGroups = useCallback((businessId, options) => { dispatch(PlanGroupActions.planGroupAllRequest(businessId, options)) }, [dispatch])

    let doc = []
    const dropdownItems = [
        { name: t("payments.collected"), code: 'CP', alias: "collected" },
        { name: t("payments.missed"), code: 'MP', alias: "missed" },
        { name: t("payments.external"), code: 'ER', alias: "external" }
    ];

    const periods = [
        selectedType.name !== "Missed Payments" && { name: t("payments.today"), code: 'DAILY', title: "today" },
        { name: t("payments.this_week"), code: '1WEEK' },
        { name: t("payments.this_month"), code: '1MONTH' },
        { name: t("dashboard.3_months_duration"), code: 'MONTHLY' },
        { name: t("dashboard.6_months_duration"), code: 'MONTHLY' },
        { name: t("dashboard.12_months_duration"), code: 'MONTHLY' },
        { name: t("dashboard.custom_duration"), code: 'CUSTOM' },
    ];

    useEffect(() => {

        const today = new Date();
        const oneMonthAgo = moment().subtract(1, 'months').format("YYYY-MM-DD")
        let customEndDate;

        const priorWeeks = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
        const lastMonths = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30)
        const last3Months = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate())
        const last6Months = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate())
        const last12Months = new Date(today.getFullYear(), today.getMonth() - 12, today.getDate())

        const todayDate = moment(today).format().split("T").shift()
        const weeklyStartDates = moment(priorWeeks).format().split("T").shift()
        const lastMonthsStartDate = moment(lastMonths).format().split("T").shift()
        const last3MonthsStartDate = moment(last3Months).format().split("T").shift()
        const last6MonthsStartDate = moment(last6Months).format().split("T").shift()
        const last12MonthsStartDate = moment(last12Months).format().split("T").shift()

        if (period.code === "DAILY") {
            customEndDate = todayDate
        }
        else if (period.code === "1WEEK") {
            customEndDate = weeklyStartDates
        }
        else if (period.code === "MONTHLY" && period.name === (t("dashboard.3_months_duration"))) {
            customEndDate = last3MonthsStartDate
        }
        else if (period.code === "CUSTOM") {
            customEndDate = last3MonthsStartDate
        }
        else if (period.code === "MONTHLY" && period.name === "This month") {
            customEndDate = lastMonthsStartDate
        }
        else if (period.code === "MONTHLY" && period.name === (t("dashboard.6_months_duration"))) {
            customEndDate = last6MonthsStartDate
        }
        else if (period.code === "MONTHLY" && period.name === (t("dashboard.12_months_duration"))) {
            customEndDate = last12MonthsStartDate
        }
        else {
            customEndDate = oneMonthAgo
        }

        window.onbeforeunload = () => {
            navigate(location.pathname, { replace: true });
        };

        const textParam = selectedBcId ? `?bcId=${selectedBcId?.busCustomerId}` : `?searchText=${selectedCustomer}`

        let groupId;
        let selectedChoice;
        if (selectedType && typeChanged) {
            selectedChoice = selectedType?.alias
        }
        else {
            selectedChoice = location?.state?.showPaymentContent
        }
        if (selectedOption) {
            groupId = selectedOption?.id
        }
        else {
            groupId = location?.state?.id
        }
        if (selectedChoice === "collected") {
            if ((selectedCustomer?.length >= 3 || selectedBcId)) {
                getCollectedPayments(defMembership.business.id, `${textParam}&groupId=${groupId}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
            }
            else {
                getCollectedPayments(defMembership?.business.id, `?groupId=${groupId}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                setSelectedType({ name: "Collected payments", code: "CP", alias: "collected" });
            }
        }
        else if (selectedChoice === "missed") {
            if ((selectedCustomer?.length >= 3 || selectedBcId)) {
                getMissedPayments(defMembership.business.id, `${textParam}&groupId=${groupId}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
            }
            else {
                getMissedPayments(defMembership?.business.id, `?groupId=${groupId}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                setSelectedType({ name: "Missed payments", code: "MP", alias: "missed" });
            }
        }
        else if (selectedChoice === "external") {
            if ((selectedCustomer?.length >= 3 || selectedBcId)) {
                getExternalPayments(defMembership.business.id, `${textParam}&groupId=${groupId}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
            }
            else {
                getExternalPayments(defMembership?.business.id, `?groupId=${groupId}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                setSelectedType({ name: "External payments", code: "ER", alias: "external" })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.state, page, state.first, period, dates, selectedOption, state.selectedCustomer, selectedCustomer, typeChanged, selectedType.name])

    useEffect(() => {
        if (!selectedCustomer)
            setState((state) => ({ ...state, selectedCustomer: null }))
    }, [selectedCustomer, selectedOption])

    useEffect(() => {
        if (planGroupsGraphedActive) {
            let planGroupAssigned = Object.assign({}, planGroupsGraphedActive);
            planGroupAssigned.groups.map((item, ind) => {
                const {
                    group,
                } = item;

                doc.push({
                    ...group
                });
                return null;
            });
        }
        if (allPlanGroups) {
            setState((state) => { return { ...state, allProductsObject: allPlanGroups } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planGroupsGraphedActive, allPlanGroups])

    useEffect(() => {
        if (selectedType.code === 'CP') {
            setData(collectedPayments?.content)
        }
        else if (selectedType.code === "MP") {
            setData(missPayments?.content)
        }
        else {
            setData(externalPayments?.content)
        }
    }, [collectedPayments, missPayments, externalPayments, selectedType])

    const loading = fetchingCollected || fetchingMissed || fetchingExternal

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            getActivePlanGroups(defMembership.business.id);
            getAllPlanGroups(defMembership.business.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const today = new Date();
        const oneMonthAgo = moment().subtract(1, 'months').format("YYYY-MM-DD")
        let customEndDate;

        const priorWeeks = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
        const lastMonths = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30)
        const last3Months = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate())
        const last6Months = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate())
        const last12Months = new Date(today.getFullYear(), today.getMonth() - 12, today.getDate())

        const endDates = moment(today).format().split("T").shift()
        const todayDate = moment(today).format().split("T").shift()
        const weeklyStartDates = moment(priorWeeks).format().split("T").shift()
        const lastMonthsStartDate = moment(lastMonths).format().split("T").shift()
        const last3MonthsStartDate = moment(last3Months).format().split("T").shift()
        const last6MonthsStartDate = moment(last6Months).format().split("T").shift()
        const last12MonthsStartDate = moment(last12Months).format().split("T").shift()

        if (period.code === "DAILY") {
            customEndDate = todayDate
        }
        else if (period.code === "1WEEK") {
            customEndDate = weeklyStartDates
        }
        else if (period.code === "MONTHLY" && period.name === (t("dashboard.3_months_duration"))) {
            customEndDate = last3MonthsStartDate
        }
        else if (period.code === "CUSTOM") {
            customEndDate = last3MonthsStartDate
        }
        else if (period.code === "MONTHLY" && period.name === "This month") {
            customEndDate = lastMonthsStartDate
        }
        else if (period.code === "MONTHLY" && period.name === (t("dashboard.6_months_duration"))) {
            customEndDate = last6MonthsStartDate
        }
        else if (period.code === "MONTHLY" && period.name === (t("dashboard.12_months_duration"))) {
            customEndDate = last12MonthsStartDate
        }
        else {
            customEndDate = oneMonthAgo
        }

        const startDate = dates && moment(dates[0]).format('YYYY-MM-DD')
        const endDate = dates && moment(dates[1]).format('YYYY-MM-DD')
        const textParam = selectedBcId ? `?bcId=${selectedBcId?.busCustomerId}` : `?searchText=${selectedCustomer}`

        if (defMembership && !location?.state) {
            if ((!selectedCustomer && !selectedBcId) && !selectedOption && (!startDate || !endDate) && period.title === "today") {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `?startDate=${endDates}&endDate=${todayDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `?startDate=${endDates}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `?startDate=${endDates}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });

                }
            }
            else if ((!selectedCustomer && !selectedBcId) && !selectedOption && (!startDate || !endDate) && (period.code === "CUSTOM" && cleared)) {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `?startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `?startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });

                }
                else {
                    getExternalPayments(defMembership.business.id, `?startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });

                }
            }
            else if ((!selectedCustomer && !selectedBcId) && !selectedOption && (!startDate || !endDate) && (period.title !== "today" && period.code !== "CUSTOM")) {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `?startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });

                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `?startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `?startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
            }
            else if ((selectedCustomer?.length >= 3 || selectedBcId) && !selectedOption && (!startDate || !endDate) && (period.title !== "today" && period.code !== "CUSTOM")) {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `${textParam}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });

                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `${textParam}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `${textParam}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
            }
            else if ((!selectedCustomer && !selectedBcId) && selectedOption && (!startDate || !endDate) && (period.title !== "today" && period.code !== "CUSTOM")) {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `?groupId=${selectedOption.id}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `?groupId=${selectedOption.id}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `?groupId=${selectedOption.id}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
            }
            else if ((selectedCustomer?.length >= 3 || selectedBcId) && !selectedOption && !dates && period.title === "today") {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `${textParam}&startDate=${endDates}&endDate=${todayDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `${textParam}&startDate=${endDates}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `${textParam}&startDate=${endDates}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
            }
            else if ((selectedCustomer?.length >= 3 || selectedBcId) && !selectedOption && !dates && period.code === "CUSTOM") {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `${textParam}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `${textParam}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `${textParam}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
            }
            else if ((!selectedCustomer && !selectedBcId) && selectedOption && !dates && period.title === "today") {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `?groupId=${selectedOption.id}&startDate=${endDates}&endDate=${todayDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `?groupId=${selectedOption.id}&startDate=${endDates}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `?groupId=${selectedOption.id}&startDate=${endDates}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
            }
            else if ((!selectedCustomer && !selectedBcId) && selectedOption && !dates && period.code === "CUSTOM") {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `?groupId=${selectedOption.id}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `?groupId=${selectedOption.id}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `?groupId=${selectedOption.id}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
            }
            else if ((selectedCustomer?.length >= 3 || selectedBcId) && selectedOption && !dates && period.title === "today") {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `${textParam}&groupId=${selectedOption.id}&startDate=${endDates}&endDate=${todayDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `${textParam}&groupId=${selectedOption.id}&startDate=${endDates}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `${textParam}&groupId=${selectedOption.id}&startDate=${endDates}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });

                }
            }
            else if ((selectedCustomer?.length >= 3 || selectedBcId) && selectedOption && !dates && period.code === "CUSTOM") {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `${textParam}&groupId=${selectedOption.id}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `${textParam}&groupId=${selectedOption.id}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `${textParam}&groupId=${selectedOption.id}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });

                }
            }
            else if ((selectedCustomer?.length >= 3 || selectedBcId) && selectedOption && !dates && (period.title !== "today" && period.code !== "CUSTOM")) {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `${textParam}&groupId=${selectedOption.id}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `${textParam}&groupId=${selectedOption.id}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `${textParam}&groupId=${selectedOption.id}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
            }
            else if ((selectedCustomer?.length >= 3 || selectedBcId) && selectedOption && !dates && (period.title !== "today" && period.code !== "CUSTOM")) {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `${textParam}&groupId=${selectedOption.id}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `${textParam}&groupId=${selectedOption.id}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `${textParam}&groupId=${selectedOption.id}&startDate=${customEndDate}&endDate=${todayDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
            }

            else if ((!selectedCustomer && !selectedBcId) && !selectedOption && (startDate && endDate !== "Invalid date")) {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `?startDate=${startDate}&endDate=${endDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `?startDate=${startDate}&endDate=${endDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `?startDate=${startDate}&endDate=${endDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
            }
            else if ((selectedCustomer?.length >= 3 || selectedBcId) && !selectedOption && (startDate && endDate !== "Invalid date")) {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `${textParam}&startDate=${startDate}&endDate=${endDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `${textParam}&startDate=${startDate}&endDate=${endDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `${textParam}&startDate=${startDate}&endDate=${endDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
            }
            else if ((!selectedCustomer && !selectedBcId) && selectedOption && (startDate && endDate !== "Invalid date")) {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `?groupId=${selectedOption.id}&startDate=${startDate}&endDate=${endDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `?groupId=${selectedOption.id}&startDate=${startDate}&endDate=${endDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `?groupId=${selectedOption.id}&startDate=${startDate}&endDate=${endDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
            }
            else if ((selectedCustomer?.length >= 3 || selectedBcId) && selectedOption && (startDate && endDate !== "Invalid date")) {
                if (selectedType.code === "CP") {
                    getCollectedPayments(defMembership.business.id, `${textParam}&groupId=${selectedOption.id}&startDate=${startDate}&endDate=${endDate}`, { page: page, size: state.size, sort: "receivedDate,desc" });
                }
                else if (selectedType.code === "MP") {
                    getMissedPayments(defMembership.business.id, `${textParam}&groupId=${selectedOption.id}&startDate=${startDate}&endDate=${endDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
                else {
                    getExternalPayments(defMembership.business.id, `${textParam}&groupId=${selectedOption.id}&startDate=${startDate}&endDate=${endDate}`, { page: page, size: state.size, sort: "scheduledDate,desc" });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.submitInput, period, selectedType, dates, selectedOption, state.selectedCustomer, selectedCustomer, page, state.first, selectedBcId,])

    const formatDate = date => {
        return moment(date).format('DD MMM YYYY')
    }

    function getTimeFromDateString(dateString) {
        const date = new Date(dateString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    function convertDate(inputDate) {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const date = new Date(inputDate);
        return date.toLocaleDateString('en-US', options);
    }

    const statusTemplate = (rowData, e) => {
        return rowData && <span className={`product-badge status-${rowData ? rowData.toLowerCase() : ""}`}>{rowData}</span>;
    };

    const markActionTemplate = (rowData) => {
        if (rowData) {
            return (rowData.subscriptionStatus === "ACTIVE" ? < Button
                label={t("subscriber.paid")}
                style={{ color: (defMembership.role !== "VIEWER") ? "#008dd5" : '#6c757d' }}
                className="p-button-warning p-button-rounded p-button-text mr-2 customerAction"
                onClick={() => {
                    if (defMembership.role !== "VIEWER") {
                        setDisplayBasic(true)
                        setState((state) => ({
                            ...state,
                            id: rowData.id,
                            flex: rowData.flex,
                            scheduledDate: rowData.scheduledDate
                        }));
                    }
                }}
            /> : "")
        }
    }

    const subscriptionBodyTemplate = (rowData) => {
        if (rowData) {
            return selectedType.code !== "MP" ? <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                <div className="subscriptionBodyTemplate">
                    {
                        rowData.planName ?
                            <p className="detail-navigation" id="tierName"
                                onClick={() => {
                                    navigate(`/products/plan/detail/${rowData.planId}`)
                                }} >  {rowData.planName} </p> : null
                    }
                    {
                        rowData.planPeriod ?
                            <p className="plan-group-text" >
                                {t(`product.${rowData.planPeriod.toLowerCase()}`)}</p> : null
                    }
                    {
                        rowData.busCustomerName ?
                            <p className="ref-text" id='tierSub' style={{ marginBottom: "3px" }}>
                                {rowData.busCustomerName}</p>
                            : rowData.customerName ? <p className="ref-text" id='tierSub' style={{ marginBottom: "3px" }}>
                                {rowData.customerName}</p> : null
                    }
                    {
                        rowData.planName ?
                            <span className="ref-text " id='tierSub' > {rowData.phoneNo} </span> : null
                    }
                    {
                        rowData.groupName ?
                            <p className="groupName" id='tierSub' >{rowData.groupName} </p> : null
                    }
                </div >
            </div > :
                <div>
                    {
                        rowData.scheduledDate ? <p className="detail-navigatio" id="scheduleId">{convertDate(rowData.scheduledDate)}</p> : null
                    }
                </div>
        }
    }
    const paymentIdBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>
                {selectedType.code === "CP" ?
                    <div>{
                        rowData.reference ?
                            <p className="detail-navigation paymentID" id="paymentId"
                                onClick={() => navigate(`/payments/detail/${rowData.id}`)}> {rowData.reference}</p> : "--"
                    }</div>
                    :
                    selectedType.code === "MP" ?
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                            <div className="subscriptionBodyTemplate">
                                {
                                    rowData.planName ?
                                        <p className="detail-navigation" id="tierName"
                                            onClick={() => {
                                                navigate(`/products/paln/detail/${rowData.planId}`)
                                            }}>{rowData.planName} </p> : null
                                }
                                {
                                    rowData.planPeriod ? <p className="plan-group-text">{t(`product.${rowData.planPeriod.toLowerCase()}`)}</p> : null
                                }
                                {
                                    rowData.customerName !== null ? <p className="ref-text customerName-txt" id='tierSub'> {rowData.customerName}</p> : null
                                }
                                {
                                    rowData.phoneNo ? <span className="ref-text " id='tierSub'> {rowData.phoneNo}</span> : null
                                }
                                {
                                    rowData.groupName ? <p className="groupName" id='tierSub'> {rowData.groupName}</p> : null
                                }
                            </div >
                        </div > :
                        <div>
                            {
                                rowData.id ? <p className="detail-navigatio" id="transactionId"> {rowData.id}</p> : null
                            }
                        </div>
                }
            </div>
        }
    }

    const occuredBodyTemplate = (rowData, e) => {
        if (rowData) {
            return <div>
                {selectedType.code === "CP" ?
                    <div>{
                        rowData.receivedAt ?
                            <div className="detail-navigatio" id="occured">
                                <span >{formatDate(rowData.receivedAt)}</span>
                                <p style={{ marginTop: "0.5rem", fontSize: "11px", color: "#8f9395" }}>{getTimeFromDateString(rowData.receivedAt)}</p>
                            </div> : '--'
                    }</div>
                    :
                    selectedType.code === "MP" ?
                        <div>{
                            rowData.status ? statusTemplate(rowData.status, e) : "--"
                        }</div> :
                        <div>{
                            rowData.externallyReceivedOn ? <div className="detail-navigatio" id="occured" >
                                <span >{formatDate(rowData.externallyReceivedOn)}</span>
                                <p style={{ marginTop: "0.5rem", fontSize: "11px", color: "#8f9395" }}>{getTimeFromDateString(rowData.externallyReceivedOn)}</p>
                            </div> : '--'
                        }</div>}
            </div>
        }
    }

    const amountBodyTemplate = (rowData, e) => {
        if (rowData) {
            return <div>
                {selectedType.code === "CP" ?
                    <div>{
                        rowData.amount ?
                            <p className="detail-navigatio" id="amount">
                                <span style={{ display: "flex", flexDirection: "column", marginBottom: "0.5rem" }}>
                                    <span className="firstPayment">{defMembership.business.currency} {rowData.amount.toFixed(2)}</span>
                                    <span className='payment-style'>{rowData.paymentMode}</span>
                                    <span className='payment-style'>{rowData.paymentModeName}</span>
                                    <span className='payment-style'>{rowData.paymentIdentifier}</span>
                                </span>
                            </p> : "--"
                    }</div>
                    :
                    selectedType.code === "MP" ?
                        <div>{
                            rowData.subscriptionStatus ? statusTemplate(rowData.subscriptionStatus, e) : '--'
                        }</div> :
                        <div>
                            {
                                rowData.flex === true ?
                                    <div>
                                        <span style={{ display: "block", marginBottom: "0.5rem" }}>{t("products.flexible_amount")}</span>
                                        {rowData.amount ? <span className="transactions_data"> {defMembership.business.country.currency} {""} {rowData.amount.toFixed(2)}</span>
                                            : "--"}
                                    </div>
                                    :
                                    <div>
                                        {rowData.amount ? <p className="transactions_data"> {defMembership.business.country.currency} {rowData.amount.toFixed(2)}</p> : '--'}
                                    </div>
                            }
                        </div>
                }
            </div>
        }

    }

    const feesBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>
                {selectedType.code === "CP" ?
                    <div>{
                        rowData.fees ?
                            <p className="detail-navigatio feePayment" id="fees" >
                                {defMembership.business.currency} {rowData.fees.toFixed(2)}
                            </p> : null
                    }</div>
                    :
                    selectedType.code === "MP" ?
                        <div>{
                            rowData.flex === true ? t("products.flexible_amount")
                                : rowData.amount ? <span className="transactions_data"> {defMembership.business.country.currency} {""} {rowData.amount.toFixed(2)}</span>
                                    : '--'}</div> :
                        <div>{rowData.extReceivedNotes ?
                            <p className="detail-navigatio" id="fees"> {rowData.extReceivedNotes} </p> : '--'
                        }</div>}
            </div>
        }
    }

    const redeemableBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>
                {selectedType.code === "CP" ?
                    <div>{
                        rowData.redeemable ?
                            <p className="detail-navigatio revenue" id="redeemable">
                                {defMembership.business.currency} {rowData.redeemable.toFixed(2)}
                            </p> : null
                    }</div>
                    : selectedType.code === 'MP' && <div>
                        <div>{
                            rowData.subscriptionStatus === 'ACTIVE' ?
                                markActionTemplate(rowData)
                                : null
                        }</div>
                    </div>

                }
            </div>
        }
    }

    let columns = [
        {
            field: "reference",
            header: selectedType.code === "CP" ? t("payments.payment_id") : selectedType.code === "MP" ? t("payments.subscription_info") : t("payments.transaction_id"),
            className: selectedType.code === "CP" ? "payment-id" : selectedType.code === "MP" ? "tier-info" : "payment-id",
            body: paymentIdBodyTemplate,
        },
        {
            field: "customerNum",
            header: selectedType.code === "CP" ? t("payments.subscription_info") : selectedType.code === "MP" ? t("payments.schedule_for") : t("payments.subscription_info"),
            className: selectedType.code !== "MP" ? "subscription-info" : "schedule-for",
            body: subscriptionBodyTemplate,
        },
        {
            field: "receivedAt",
            header: selectedType.code === "CP" ? t("payments.occurred_on") : selectedType.code === "MP" ? t("payments.schedule_status") : t("payments.occurred_on"),
            className: selectedType.code === "CP" ? "occurred-on" : selectedType.code === "MP" ? "schedule-status-payments" : "occurred-on",
            body: occuredBodyTemplate,
            sort: true,
        },
        {
            field: "amount",
            header: selectedType.code === "CP" ? t("payments.collect") : selectedType.code === "MP" ? t("payments.subscription_status") : t("payments.amount"),
            className: selectedType.code === "CP" ? "collected" : selectedType.code === "MP" ? "subscription-status" : "collected",
            body: amountBodyTemplate,
            sort: true,
        },
        {
            field: "fees",
            header: selectedType.code === "CP" ? t("payments.service_fees") : selectedType.code === "MP" ? t("payments.amount") : t("payments.note"),
            className: selectedType.code === "CP" ? "fees" : selectedType.code === "MP" ? "collected" : "note",
            body: feesBodyTemplate,
            sortable: selectedType.code !== "ER" && true,
        },

    ];

    // Conditionally add the last object based on selectedType.code
    if (selectedType.code !== "ER") {
        columns.push({
            field: "redeemable",
            className: selectedType.code === "MP" || selectedType.code === "CP" ? "revenue" : 'no-revenue',
            header: selectedType.code === 'CP' && t("payments.revenue"),
            body: redeemableBodyTemplate,
            sort: selectedType.code === 'CP' && true,
        },);
    }

    const today = new Date();
    const todayDate = moment(today).format().split("T").shift()
    const endDates = moment(defMembership.joinedOn).format().split("T").shift()
    const totalRecords = selectedType.code === "CP" ?
        collectedPayments?.totalElements : selectedType.code === "MP" ? missPayments?.totalElements
            : externalPayments?.totalElements


    const onPageChange = (e) => {
        if (e.pageCount <= 1) {
            return;
        }
        setState((state) => ({ ...state, first: e.first }));
        setPage(e.page)
    }

    const updateFirst = () => {
        setState((state) => { return { ...state, first: 0 } })
    }

    return (
        <>
            <div>
                <MarkAsPaid displayDialog={displayBasic} onHide={() => { setDisplayBasic(false) }}
                    scheduleId={state.id}
                    isFlexTrue={state.flex}
                    scheduledDate={state.scheduledDate}
                    callbackFunctions={() => {
                        getCollectedPayments(defMembership.business.id, `?startDate=${endDates}&endDate=${todayDate}`, { sort: "receivedDate,desc" });
                        getMissedPayments(defMembership.business.id, `?startDate=${endDates}&endDate=${todayDate}`, { sort: "scheduledDate,desc" });
                        getExternalPayments(defMembership.business.id, `?startDate=${endDates}&endDate=${todayDate}`, { sort: "scheduledDate,desc" });
                    }} />

                <ActionBar selectedType={selectedType} setSelectedType={setSelectedType} dropdownItems={dropdownItems} selectedOption={selectedOption} setPage={setPage} updateFirstState={updateFirst}
                    summaryOptions={state.allProductsObject} setSelectedOption={setSelectedOption} selectedCustomer={selectedCustomer} selectedBcId={selectedBcId} filteredCustomer={filteredCustomer}
                    setFilteredCustomer={setFilteredCustomer} setSelectedCustomer={setSelectedCustomer} data={data} setData={setData} setSelectedBcId={setSelectedBcId} periods={periods} period={period}
                    setDates={setDates} setPeriod={setPeriod} dates={dates} state={state} filtered={filtered} setCleared={setCleared} setFiltered={setFiltered} setState={setState}
                    setTypeChanged={setTypeChanged} placeholder={location.state?.id ? location.state.name : t("summary_reports.choose_product")} summaryOption={true} location={location} 
                    total={selectedType.code === "CP" ? collectedPayments?.totalElements : selectedType.code === "MP" ? missPayments?.totalElements : externalPayments?.totalElements} />

                <CustomDataTable columns={columns}
                    loading={loading}
                    value={selectedType.code === "CP" ? collectedPayments?.content : selectedType.code === "MP" ? missPayments?.content : externalPayments?.content}>
                </CustomDataTable>

                <Paginator rows={totalRecords?.length === 0 ? 0 : state.size} totalRecords={totalRecords} first={state.first} onPageChange={onPageChange} ></Paginator>
            </div >
        </>
    )
}
export default Payments;