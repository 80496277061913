import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client"
import AppRoot from "./AppRoot";
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './Components/ErrorFallback';
import "core-js/stable";
import "regenerator-runtime/runtime";
import "prismjs/themes/prism-coy.css";
import "./Config/i18n";
import "primeflex/primeflex.css"
import KeycloakProviderWrapper from "./Pages/Authentication/keycloakProvider";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";     
    
//core
import "primereact/resources/primereact.min.css";                                       

// Get the root DOM element (where the app will be mounted)
const rootElement = document.getElementById('root');

// Create a root and render the application
ReactDOM.createRoot(rootElement).render(
	<KeycloakProviderWrapper>
	  <ErrorBoundary FallbackComponent={ErrorFallback}>
		<AppRoot />
	  </ErrorBoundary>
	</KeycloakProviderWrapper>
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
