// Login.js
import React from "react";
import { Button } from "primereact/button";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/Reveknew_Logo_Colour_and_Black.png";
import ImplicitCallback from "./Authentication";
import Loading from "../Components/Loading";

export default function Login() {
  const { t } = useTranslation("common");
  const { keycloak, initialized } = useKeycloak();

  const login = () => {
    // keycloak.login();  // Trigger Keycloak login
    keycloak.login({
      redirectUri: window.location.href, // Dynamically redirect after login
    });
  };

  if (!initialized) {
    return <div style={{height: "100vh"}}><Loading/></div>
  }

  if (!keycloak.authenticated) {
    return (
      <div className="login-body">
      <div className="login-panel"></div>

      <div className="login-content" style={{ marginTop: "5rem" }}>
        <img src={logo} alt="babylon-layout" />
        <h1>
          <span>{t("login.login_text")} </span>{t("login.reveknew_text")}
        </h1>
        <p>{t("login.welcome_text")}</p>

        <Button label={t("login.login_label")} onClick={login} />
      </div>
    </div>
    );
  } else {
    return <ImplicitCallback/>;
  }
}
