import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
	paySwitchCredentialRequest: ["businessId"],
	paySwitchCredentialCreateRequest: ["businessId", "credentials"],
	paymentCredentialRequest: ["businessId"],
	updatePaySwitchMerchantIdRequest: ["businessId", "credentials"],
	updatePaySwitchCredentialsRequest: ["businessId", "credentials"],

	paySwitchCredentialSuccess: ["credentials"],
	paySwitchCredentialCreateSuccess: ["createdCredentials"],
	paymentCredentialSuccess: ["paymentCredentials"],
	updatePaySwitchMerchantIdSuccess: ["updatedMerchantId"],
	updatePaySwitchCredentialsSuccess: ["updatedCredentials"],

	paySwitchCredentialFailure: ["error"],
	paySwitchCredentialCreateFailure: ["error"],
	paymentCredentialFailure: ["error"],
	updatePaySwitchMerchantIdFailure: ["error"],
	updatePaySwitchCredentialsFailure: ["error"],
});

export const PaySwitchTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	fetchingCredentials: false,
	creating: false,
	fetchingPaymentCredentials: false,
	updatingPaySwitchMerchantId: false,
	updatingPaySwitchCredentials: false,

	credentials: null,
	createdCredentials: null,
	paymentCredentials: null,
	updatedPaySwitchMerchantId: null,
	updatedPaySwitchCredentials: null,

	errorCredentials: null,
	errorCreatingCredentials: null,
	errorPaymentCredentials: null,
	errorUpdatingPaySwitchMerchantId: null,
	errorUpdatingPaySwitchCredentials: null,
});

/* ------------- Reducers ------------- */

// request the data from an api
export const credentialsRequest = (state) => {
	return { ...state, fetchingCredentials: true, credentials: null, errorCredentials: null };
};

// request to create from an api
export const createRequest = (state) => {
	return { ...state, creating: true, errorCreatingCredentials: null, createdCredentials: null };
};

export const paymentCredentialsRequest = (state) => {
	return { ...state, fetchingPaymentCredentials: true, paymentCredentials: null, errorPaymentCredentials: null };
};

// request to update from an api
export const updateMerchantIdRequest = (state) => {
	return { ...state, updatingPaySwitchMerchantId: true };
};

// request to update from an api
export const updateCredentialsRequest = (state) => {
	return { ...state, updatingPaySwitchCredentials: true };
};


// successful api lookup for single entity
export const success = (state, action) => {
	const { credentials } = action;
	return { ...state, fetchingCredentials: false, errorCredentials: null, credentials };
};

// successful api create
export const createSuccess = (state, action) => {
	const { createdCredentials } = action;
	return { ...state, creating: false, errorCreatingCredentials: null, createdCredentials: createdCredentials };
};

export const paymentSuccess = (state, action) => {
	const { paymentCredentials } = action;
	return { ...state, fetchingPaymentCredentials: false, errorPaymentCredentials: null, paymentCredentials };
};

// successful api update
export const updateMerchantIdSuccess = (state, action) => {
	const { updatedMerchantId } = action;
	return { ...state, updatingPaySwitchMerchantId: false, errorUpdatingPaySwitchMerchantId: null, updatedMerchantId };
};

// successful api update
export const updateCredentialsSuccess = (state, action) => {
	const { updatedCredentials } = action;
	return { ...state, updatingPaySwitchCredentials: false, errorUpdatingPaySwitchCredentials: null, updatedCredentials };
};


// Something went wrong fetching a single entity.
export const failure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingCredentials: false, errorCredentials: error, credentials: null };
};

// Something went wrong creating.
export const createFailure = (state, action) => {
	const { error } = action;
	return { ...state, creating: false, errorCreatingCredentials: error, createdCredentials: null };
};

export const paymentFailure = (state, action) => {
	const { error } = action;
	return { ...state, fetchingPaymentCredentials: false, errorPaymentCredentials: error, paymentCredentials: null };
};

// Something went wrong updating.
export const updateMerchantIdFailure = (state, action) => {
	const { error } = action;
	return { ...state, updatingPaySwitchMerchantId:false, errorUpdatingPaySwitchMerchantId: error, updatedPaySwitchMerchantId: {} };
};

// Something went wrong updating.
export const updateCredentialsFailure = (state, action) => {
	const { error } = action;
	return { ...state, updatingPaySwitchCredentials:false, errorUpdatingPaySwitchCredentials: error, updatedPaySwitchCredentials: {} };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.PAY_SWITCH_CREDENTIAL_REQUEST]: credentialsRequest,
	[Types.PAY_SWITCH_CREDENTIAL_CREATE_REQUEST]: createRequest,
	[Types.PAYMENT_CREDENTIAL_REQUEST]: paymentCredentialsRequest,
	[Types.UPDATE_PAY_SWITCH_MERCHANT_ID_REQUEST]: updateMerchantIdRequest,
	[Types.UPDATE_PAY_SWITCH_CREDENTIALS_REQUEST]: updateCredentialsRequest,

	[Types.PAY_SWITCH_CREDENTIAL_SUCCESS]: success,
	[Types.PAY_SWITCH_CREDENTIAL_CREATE_SUCCESS]: createSuccess,
	[Types.PAYMENT_CREDENTIAL_SUCCESS]: paymentSuccess,
	[Types.UPDATE_PAY_SWITCH_MERCHANT_ID_SUCCESS]: updateMerchantIdSuccess,
	[Types.UPDATE_PAY_SWITCH_CREDENTIALS_SUCCESS]: updateCredentialsSuccess,

	[Types.PAY_SWITCH_CREDENTIAL_FAILURE]: failure,
	[Types.PAY_SWITCH_CREDENTIAL_CREATE_FAILURE]: createFailure,
	[Types.PAYMENT_CREDENTIAL_FAILURE]: failure,
	[Types.UPDATE_PAY_SWITCH_MERCHANT_ID_FAILURE]: updateMerchantIdFailure,
	[Types.UPDATE_PAY_SWITCH_CREDENTIALS_FAILURE]: updateCredentialsFailure
});
